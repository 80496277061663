/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  --mso-photoalbum-image-aspect-ratio: var(--ww-widget-ctas-image-aspect-ratio);
}

.widget--photoalbum .masonry-grid-w-photoalbum__grid-sizer,
.widget--photoalbum .masonry-grid-w-photoalbum__grid-item {
  width: calc(50% - 5px);
}
.widget--photoalbum .masonry-grid-w-photoalbum__gutter-sizer {
  width: 10px;
}
.widget--photoalbum .masonry-grid-w-photoalbum__grid-item {
  margin-bottom: 10px;
}
.widget--photoalbum .masonry-grid-w-photoalbum__grid-item img {
  display: block;
  max-width: 100%;
}

.w-photoalbum {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 600px;
}
.w-photoalbum--grid {
  height: auto;
}
.w-photoalbum__items {
  width: calc(50% - 3px);
  position: relative;
}
.w-photoalbum__items .w-photoalbum__photocontainer,
.w-photoalbum__items .w-photoalbum__photo {
  width: 100%;
  height: 100%;
}
.w-photoalbum__items--left {
  height: 100%;
}
.w-photoalbum__items--grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media (min-width: 768px) {
  .w-photoalbum__items--grid {
    grid-template-columns: repeat(auto-fit, minmax(286px, 1fr));
  }
}
.w-photoalbum__items--grid .w-photoalbum__photo {
  position: absolute;
  left: 0;
  top: 0;
}
.w-photoalbum__items--grid .w-photoalbum__photoimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.w-photoalbum__items--grid .w-photoalbum__photocontainer {
  width: 100%;
  height: 0;
  padding-bottom: var(--mso-photoalbum-image-aspect-ratio);
  border-radius: var(--ww-widget-ctas-image-border-radius);
}
.w-photoalbum__info {
  font-size: 12px;
  padding: 10px;
  position: absolute;
  left: 10px;
  z-index: 10;
  color: #fff;
  background: rgba(0, 0, 0, 0.85);
  pointer-events: none;
}
.w-photoalbum__info--num {
  bottom: 10px;
}
.w-photoalbum__info--fullscreen {
  top: 10px;
}
.w-photoalbum__photocontainer {
  position: relative;
  overflow: hidden;
}
.w-photoalbum__photo {
  display: block;
  text-decoration: none;
  transition: transform 0.3s;
  width: 100%;
  height: 100%;
  position: relative;
}
.w-photoalbum__photo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}
.w-photoalbum__photo--other {
  display: none;
}
.w-photoalbum__photo:hover::after {
  opacity: 0.25;
}
.w-photoalbum--single .w-photoalbum__items {
  width: 100%;
}
.w-photoalbum--single .w-photoalbum__items--right {
  display: none;
}
.w-photoalbum--collage-3 .w-photoalbum__items--right, .w-photoalbum--collage-6 .w-photoalbum__items--right {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: space-between;
}
.w-photoalbum--collage-3 .w-photoalbum__items--right .w-photoalbum__photocontainer, .w-photoalbum--collage-6 .w-photoalbum__items--right .w-photoalbum__photocontainer {
  width: 50%;
  height: calc(25% + 3px);
  padding: 0 4px 8px 4px;
}
.w-photoalbum--collage-3 .w-photoalbum__items--right .w-photoalbum__photocontainer:first-child, .w-photoalbum--collage-6 .w-photoalbum__items--right .w-photoalbum__photocontainer:first-child {
  width: 100%;
  height: calc(50% + 3px);
}
@media screen and (max-width: 750px) {
  .w-photoalbum--collage-3 .w-photoalbum__items, .w-photoalbum--collage-6 .w-photoalbum__items {
    width: 100%;
  }
  .w-photoalbum--collage-3 .w-photoalbum__items--right, .w-photoalbum--collage-6 .w-photoalbum__items--right {
    display: none;
  }
}
.w-photoalbum--collage-3 .w-photoalbum__items--right .w-photoalbum__photocontainer {
  width: 100%;
  height: calc(50% + 7px);
  padding-bottom: 10px;
}
.w-photoalbum__more {
  font-size: 32px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.gslide-description {
  display: none;
}

.widget--photoalbum .w-slideshow {
  height: auto;
}
.widget--photoalbum .swiper-container {
  max-height: var(--ww-widget-slideshow-max-height);
}
.widget--photoalbum .swiper-container .swiper-wrapper {
  align-items: center;
}
.widget--photoalbum .swiper-container .swiper-wrapper .w-photoalbum__photoimg {
  display: block;
  margin: auto;
}
.widget--photoalbum .swiper-container .swiper-wrapper,
.widget--photoalbum .swiper-container .swiper-slide,
.widget--photoalbum .swiper-container .glightbox,
.widget--photoalbum .swiper-container .w-photoalbum__photoimg {
  max-height: unset;
}