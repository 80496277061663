/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.w-overview__titlebarcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767.98px) {
  .w-overview__titlebarcontainer {
    flex-direction: column;
    align-items: flex-start;
  }
}
.w-overview__titlebarcontainer--notitle {
  margin-bottom: 2rem;
}
.w-overview__title {
  margin-bottom: var(--ww-font-h2-margin-bottom);
}
@media (max-width: 767.98px) {
  .w-overview__titlebarlink {
    margin-bottom: var(--ww-font-h3-margin-bottom);
  }
}
.w-overview__titlebarlink {
  text-decoration: underline;
}
@media (hover: hover) {
  .w-overview:hover {
    text-decoration: none;
  }
}