/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.ww-dialog.textvideo {
  width: 90vw;
  height: 80vh;
  overflow-y: visible;
}
.ww-dialog.textvideo .ww-dialog__closebutton {
  top: 0;
  right: 0;
  transform: translate(0, calc(-100% - 10px));
}

.ww-w-textvideo {
  border-radius: var(--ww-widget-textimage-border-radius);
  overflow: hidden;
}
.ww-w-textvideo__textcontainer {
  background: var(--ww-widget-textvideo-background-color, var(--ww-primary-color));
  color: var(--ww-widget-textvideo-text-color);
  padding: var(--ww-widget-textvideo-text-padding);
}
.ww-w-textvideo__text > :first-child {
  margin-top: 0;
  margin-bottom: 0.3em;
}
.ww-w-textvideo__image {
  height: 300px;
  position: relative;
}
.ww-w-textvideo__image:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.ww-w-textvideo__videoplaybutton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 6px solid #fff;
  border-radius: 50%;
  color: #f5f5f5;
  transition: background 0.3s, box-shadow 0.3s, text-shadow 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (hover: hover) {
  .ww-w-textvideo__videoplaybutton:hover {
    background-color: var(--ww-primary-color);
    box-shadow: 0px 0px 10px var(--ww-primary-color);
    text-shadow: 0px 0px 10px var(--ww-primary-color);
  }
  .ww-w-textvideo__videoplaybutton:hover .ww-textvideo__playicon {
    border-color: transparent transparent transparent #fff;
  }
}
@media (min-width: 768px) {
  .ww-w-textvideo {
    display: block;
    width: 100%;
    position: relative;
  }
  .ww-w-textvideo__textcontainer {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 5rem 0;
    min-height: var(--ww-widget-textvideo-min-height);
  }
  .ww-w-textvideo__text {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: calc(620px + 1rem);
  }
  .ww-w-textvideo__imagecontainer {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
  .ww-w-textvideo__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (min-width: 768px) {
  .ww-w-textvideo__text {
    padding-right: 4rem;
  }
}

.ww-textvideo__playicon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20.5px 0 20.5px 35px;
  border-color: transparent transparent transparent var(--ww-primary-color);
  transition: 0.3s;
  border-style: solid;
  width: 0px;
  height: 0px;
  line-height: 0px;
  display: block;
  transform: translateX(5px);
}