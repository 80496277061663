/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.ww-mobilemenu {
  display: none;
  transform: translateX(-150vw);
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  transition: transform 0.4s;
  background: #000;
  overflow-y: auto;
}
.ww-mobilemenu.init {
  display: block;
}
html.ww-mobile-menu-open .ww-mobilemenu {
  transform: translateX(0);
  pointer-events: auto;
}

.ww-mobilemenu__close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.ww-mobilemenu__close:hover {
  opacity: 1;
}

.ww-mobilemenu__close:before, .ww-mobilemenu__close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: var(--ww-hamburger-color);
}

.ww-mobilemenu__close:before {
  transform: rotate(45deg);
}

.ww-mobilemenu__close:after {
  transform: rotate(-45deg);
}

.ww-mobilemenu__list {
  font-size: 26px;
  line-height: 120%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem 1rem 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
}

.ww-mobilemenu__link,
.ww-mobilemenu__link:not(.btn) {
  color: var(--ww-mobilemenu-link-color);
}
.ww-mobilemenu__link.ww-mobilemenu__link--selected,
.ww-mobilemenu__link:not(.btn).ww-mobilemenu__link--selected {
  color: var(--ww-mobilemenu-link-selected-color);
}

.ww-mobilemenu > ul.ww-mobilemenu__list > li.ww-mobilemenu__item .ww-mobilemenu__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ww-mobilemenu > ul.ww-mobilemenu__list > li.ww-mobilemenu__item > ul.ww-mobilemenu__list {
  padding-top: 0;
}

.ww-mobilemenu__link--hassubitems:not(.ww-mobilemenu__link--active) .ww-dropdown-icon-container {
  transform: scaleY(-1);
}
.ww-mobilemenu__link--hassubitems:not(.ww-mobilemenu__link--active) + .ww-mobilemenu__list {
  display: none;
}

.ww-mobilemenu__link .ww-chevron--down::after {
  width: 12px;
  height: 12px;
}