/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
:root {
  --ww-primary-color: red;
  --ww-grid-width: 1160px;
  --ww-grid-margin-outer: 2rem;
  --ww-grid-padding-x: 1rem;
  /* Typography */
  --ww-base-font-family: Arial, sans-serif;
  --ww-base-font-size: 1rem;
  --ww-base-line-height: 130%;
  --ww-base-font-weight: 400;
  --ww-font-h1-size: 3.5rem;
  --ww-font-h2-size: 2.5rem;
  --ww-font-h3-size: 2rem;
  --ww-font-h4-size: 1.5rem;
  --ww-font-h1-line-height: 100%;
  --ww-font-h2-line-height: 100%;
  --ww-font-h3-line-height: 100%;
  --ww-font-h4-line-height: 100%;
  --ww-font-h1-margin-bottom: 0.5em;
  --ww-font-h2-margin-bottom: 0.7em;
  --ww-font-h3-margin-bottom: 0.7em;
  --ww-font-h4-margin-bottom: 0.7em;
  --ww-font-h1-weight: 700;
  --ww-font-h2-weight: 700;
  --ww-font-h3-weight: 700;
  --ww-font-h4-weight: 700;
  /* Header */
  --ww-header-height: 125px;
  --ww-header-bg-color: var(--ww-primary-color);
  --ww-header-bg-color-hover: #000;
  --ww-header-text-color: #fff;
  --ww-header-link-color: inherit;
  --ww-header-link-color-hover: var(--ww-header-link-color);
  --ww-header-link-text-decoration: none;
  --ww-header-link-selected-color: inherit;
  --ww-header-link-selected-color-hover: var(--ww-header-link-selected-color);
  --ww-header-link-selected-text-decoration: none;
  --ww-header-box-shadow: none; /* 0px 3px 6px rgba(0, 0, 0, 0.16); */
  --ww-header-margin-bottom: 0px;
  --ww-header-item-margin: 2rem;
  --ww-header-subitem-text-color: var(--ww-header-text-color);
  --ww-header-subitem-bg-color: var(--ww-header-bg-color);
  --ww-header-subitem-border: none;
  --ww-header-subitem-text-color-hover: #fff;
  --ww-header-subitem-bg-color-hover: var(--ww-header-subitem-bg-color);
  --ww-mobilemenu-link-color: inherit;
  --ww-mobilemenu-link-selected-color: inherit;
  /* Links */
  --ww-link-color: var(--ww-primary-color);
  /* Hamburger (mobile menu) */
  --ww-hamburger-color: var(--ww-primary-color);
  /* Footer */
  --ww-footer-bg-color: var(--ww-primary-color);
  --ww-footer-text-color: #fff;
  --ww-footer-heading-color: var(--ww-footer-text-color);
  --ww-footer-margin-top: 2rem;
  --ww-footer-padding-top: 3rem;
  --ww-footer-padding-bottom: 3rem;
  --ww-footer-grid-gap: 2rem 3rem;
  /* Buttons */
  --ww-button-min-width: 120px;
  --ww-button-padding: 0.75rem 0.75rem;
  --ww-button-border-radius: 0;
  --ww-button-bg-color: var(--ww-primary-color);
  --ww-button-text-color: #fff;
  --ww-button-border: initial;
  --ww-button-border-color: var(--ww-primary-color);
  --ww-button-bg-color-hover: var(--ww-primary-color);
  --ww-button-text-color-hover: #fff;
  --ww-button-border-color-hover: var(--ww-primary-color);
  --ww-button-text-transform: initial;
  --ww-button-font-size: initial;
  --ww-button-line-height: var(--ww-base-line-height);
  /* Cookie wall */
  --ww-cookiewall-background-color: rgba(255, 255, 255, 1);
  --ww-cookiewall-backdrop-color: rgba(0, 0, 0, 0.6);
  --ww-cookiewall-heading-color: #272727;
  --ww-cookiewall-text-color: #585858;
  --ww-cookiewall-link-text-color: #a3a3a3;
  --ww-cookiewall-switch-color: #008d00;
  /* Page: overview */
  --ww-page-overview-block-box-shadow: var(--lm-shadow);
  --ww-page-overview-block-margin: 0 0 2rem 0;
  --ww-page-overview-block-image-width: 200px;
  --ww-page-overview-block-image-margin: 0;
  --ww-page-overview-block-content-padding: 20px 40px 20px 20px;
  --ww-page-overview-card-margin: 1rem;
  /* Widget: CTAs */
  --ww-widget-ctas-grid-gap: 1.5rem;
  /* Widget: slideshow */
  --ww-widget-slideshow-height: 650px;
  --ww-widget-slideshow-max-height: 50vh;
  --ww-widget-slideshow-text-shadow: none;
  --ww-widget-slideshow-bg-overlay: rgba(0, 0, 0, 0.5);
  --ww-widget-slideshow-navigation-color: var(--ww-primary-color);
  --ww-widget-slideshow-pagination-color: var(--ww-primary-color);
  /* Widget: text and image */
  --ww-widget-textimage-column-text-width: 58%;
  --ww-widget-textimage-column-image-width: 37%;
  --ww-widget-textimage-border-radius: 10px;
  --ww-widget-textimage-text-align-items: center;
  --ww-widget-textimage-image-align-items: center;
  /* Widget: text and video */
  --ww-widget-textvideo-text-color: #fff;
  --ww-widget-textvideo-min-height: 600px;
  --ww-widget-textvideo-text-padding: 3rem 1rem;
  /* Component: crumb(le) path */
  --ww-crumbpath-margin: 0 0 2rem;
  /* Component: copyright balk */
  --ww-copyrightbar-bg-color: var(--ww-primary-color);
  --ww-copyrightbar-text-color: #fff;
  --ww-copyrightbar-link-color: inherit;
  --ww-copyrightbar-padding: 1.5rem 0;
  /* Forms */
  --ww-form-color-error-message: #c00;
  --ww-form-color-error-required-indicator: inherit;
  --ww-form-color-error-border: var(--ww-form-color-error-message);
  --ww-form-textinput-disabled-bg-color: #e9ecef;
  --ww-form-textinput-font-size: 16px;
}
@media (min-width: 768px) {
  :root {
    --ww-grid-margin-outer: 4rem;
    --ww-grid-padding-x: 2rem;
  }
}
@media (min-width: 992px) {
  :root {
    --ww-grid-margin-outer: 6rem;
    --ww-grid-padding-x: 3rem;
  }
}