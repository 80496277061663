/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
[hidden] {
  display: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--ww-base-font-family);
  font-weight: var(--ww-base-font-weight);
  font-size: var(--ww-base-font-size);
  line-height: var(--ww-base-line-height);
}

h1,
.h1 {
  font-size: var(--ww-font-h1-size);
  line-height: var(--ww-font-h1-line-height);
  font-weight: var(--ww-font-h1-weight);
  margin-bottom: var(--ww-font-h1-margin-bottom);
}

h2,
.h2 {
  font-size: var(--ww-font-h2-size);
  line-height: var(--ww-font-h2-line-height);
  font-weight: var(--ww-font-h2-weight);
  margin-bottom: var(--ww-font-h2-margin-bottom);
}

h3,
.h3 {
  font-size: var(--ww-font-h3-size);
  line-height: var(--ww-font-h3-line-height);
  font-weight: var(--ww-font-h3-weight);
  margin-bottom: var(--ww-font-h3-margin-bottom);
}

h4,
.h4 {
  font-size: var(--ww-font-h4-size);
  line-height: var(--ww-font-h4-line-height);
  font-weight: var(--ww-font-h4-weight);
  margin-bottom: var(--ww-font-h4-margin-bottom);
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.ww-p-overview .c-filters__filtertitle::marker {
  display: none;
}
.ww-p-overview .c-filters__filterbuttons {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 1rem;
}
.ww-p-overview__indexgrid--blocks .ww-p-overview__indexcontent {
  padding: var(--ww-page-overview-block-content-padding);
}
@media (min-width: 576px) {
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indexitem {
    display: flex;
    margin: var(--ww-page-overview-block-margin);
    box-shadow: var(--ww-page-overview-block-box-shadow) !important;
  }
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indeximage {
    flex: 0 0 var(--ww-page-overview-block-image-width);
    margin-right: var(--ww-page-overview-block-image-margin);
  }
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indeximage-img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .ww-p-overview__indexgrid--blocks .ww-p-overview__indextitle {
    font-size: 3rem;
    margin-top: 0;
  }
}

.ww-p-overviewpageitem__newsdate {
  font-weight: 700;
  margin-bottom: 1rem;
}

.ww-p-overview__button-filter {
  margin-bottom: 1rem;
}

.crumbpath {
  display: flex;
  margin: var(--ww-crumbpath-margin);
  align-items: center;
  flex-flow: row wrap;
}
.crumbpath a.crumbpath__item {
  white-space: nowrap;
}
.crumbpath__items a, .crumbpath__items span {
  padding-left: 4px;
  padding-right: 4px;
}
.crumbpath__separator {
  margin: 0 9px;
}

.crumbpath__outergrid {
  width: 100%;
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.footer {
  background-color: var(--ww-footer-bg-color);
  color: var(--ww-footer-text-color);
  margin-top: var(--ww-footer-margin-top);
  padding-top: var(--ww-footer-padding-top);
  padding-bottom: var(--ww-footer-padding-bottom);
}
.footer h2, .footer h3, .footer h4 {
  color: var(--ww-footer-heading-color);
}
.footer__cols {
  display: grid;
  grid-gap: var(--ww-footer-grid-gap);
}
@media (min-width: 576px) {
  .footer__cols--4, .footer__cols--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .footer__cols--4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1200px) {
  .footer__cols--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 576px) {
  .footer__cols--2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.footer__col > *:first-child {
  margin-top: 0;
}
.footer__col > *:last-child {
  margin-bottom: 0;
}
.footer a:not(.btn) {
  color: inherit;
  text-decoration: underline;
}
@media (hover: hover) {
  .footer a:not(.btn):hover {
    text-decoration: none;
  }
}

/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
.ww-menu {
  display: none;
}

.ww-languagemenu__link {
  display: flex;
  align-items: center;
  gap: 1em;
}

.ww-header {
  z-index: 9999;
  background-color: var(--ww-header-bg-color);
  box-shadow: var(--ww-header-box-shadow);
  display: flex;
  align-items: center;
}
html.ww-fixedheader .ww-header {
  position: fixed;
  height: calc(var(--ww-header-height) + var(--ww-header-margin-bottom));
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100vw;
}
html.ww-fixedheader .ww-header,
html.ww-fixedheader .ww-header .ww-header__logo {
  transition: height 0.3s;
}
.ww-header--alwayshamburger #toggle-mobile-menu {
  display: block;
}
.ww-header #toggle-mobile-menu:focus {
  outline: none;
}
.ww-header__desktopmenu {
  display: none;
  align-items: center;
}
.ww-header__ctabuttoncontainer {
  margin-left: var(--ww-header-item-margin);
}
.ww-header__grid {
  width: 100%;
}
.ww-header__nav {
  display: flex;
  justify-content: space-between;
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item > ul.ww-menu ul {
  transform: translate(-10px, 0);
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item > ul.ww-menu ul.show {
  transform: translate(0, 0);
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item {
  display: flex;
  align-items: center;
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item .ww-menu-link {
  background: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item li.ww-menu-item {
  margin-left: 0;
  width: 200px;
  max-width: 300px;
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item li.ww-menu-item .ww-menu-link {
  text-decoration: none;
  background: var(--ww-header-subitem-bg-color);
  color: var(--ww-header-subitem-text-color);
  border: var(--ww-header-subitem-border);
}
@media (hover: hover) {
  .ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item li.ww-menu-item .ww-menu-link:hover {
    color: var(--ww-header-subitem-text-color-hover);
    background: var(--ww-header-subitem-bg-color-hover);
  }
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item li.ww-menu-item .ww-menu-link .ww-dropdown-icon {
  transform: rotate(-90deg);
}
.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item li.ww-menu-item .ww-menu-link.ww-menu-link ww-dropdown-toggle {
  display: flex;
  justify-content: space-between;
}
.ww-header__logo {
  display: block;
  height: var(--ww-header-logo-height);
}
.ww-header *:focus-visible {
  outline: 2px dashed black !important;
  outline-offset: -2px !important;
  box-shadow: 0 0 0 2px var(--ww-header-text-color) inset !important;
}
.ww-header__nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  color: var(--ww-header-text-color);
}
.ww-header .ww-menu-toggle {
  padding: 0;
}
.ww-header #ww-main-menu > .ww-menu-toggle {
  display: block;
  margin-left: auto;
  background: transparent;
  border: 0;
  color: var(--ww-header-text-color);
  font-size: 2rem;
}
.ww-header .ww-visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.ww-header .ww-menu-item {
  position: relative;
}
.ww-header .ww-menu {
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ww-header .ww-menu.ww-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
  transform: translate(0, -15px);
}
.ww-header .ww-menu.ww-dropdown.show {
  opacity: 1;
  pointer-events: auto;
  transform: translate(0, 0);
}
@media screen and (min-width: 1070px) {
  .ww-header .ww-menu.ww-dropdown li.ww-menu-item .ww-menu.ww-dropdown {
    top: 0;
    left: 100%;
  }
}
.ww-header .ww-menu > li.ww-menu-item > ul.ww-menu .ww-menu-item .ww-menu-link {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (max-width: 1069px) {
  .ww-header .ww-menu .ww-menu .ww-menu-link {
    padding-left: 3rem;
  }
  .ww-header .ww-menu .ww-menu .ww-menu .ww-menu-link {
    padding-left: 4rem;
  }
}
.ww-header .ww-menu .ww-menu-link,
.ww-header .ww-menu .ww-menu-toggle {
  display: flex;
  justify-content: space-between;
  background-color: var(--ww-header-bg-color);
  color: var(--ww-header-link-color);
  text-decoration: none;
  border: none;
  transition: color 0.3s, background 0.3s;
  text-decoration: var(--ww-header-link-text-decoration);
}
@media (hover: hover) {
  .ww-header .ww-menu .ww-menu-link:hover,
  .ww-header .ww-menu .ww-menu-toggle:hover {
    text-decoration: underline;
    color: var(--ww-header-link-color-hover);
  }
}
.ww-header .ww-menu .ww-menu-link {
  white-space: initial;
}
.ww-header .ww-menu .ww-menu-link--selected {
  color: var(--ww-header-link-selected-color);
  text-decoration: var(--ww-header-link-selected-text-decoration);
}
@media (hover: hover) {
  .ww-header .ww-menu .ww-menu-link--selected:hover {
    color: var(--ww-header-link-selected-color-hover);
  }
}
.ww-header .ww-menu .ww-menu-link:active {
  color: black;
}
.ww-header .ww-menu .ww-menu-item {
  position: relative;
  margin: 0 0 0 var(--ww-header-item-margin);
}
.ww-header .ww-menu .ww-menu-item.ww-dropdown > .ww-dropdown-toggle .ww-dropdown-icon-container {
  display: inline-block;
  transform: translate(6px, 2px);
  font-size: 0.9em;
}
.ww-header .ww-menu .ww-menu-item .ww-menu.ww-dropdown.show {
  display: flex;
}
@media screen and (min-width: 1070px) {
  .ww-header .ww-menu .ww-menu-item .ww-menu.ww-dropdown.show {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
  }
}
.ww-header .hamburger {
  padding-left: 0;
  padding-right: 0;
}

.show-ww-cookiewall .ww-cookiewall__container, .show-ww-cookiewall .ww-cookiewall {
  display: block;
}

.hide-ww-cookiewall .ww-cookiewall__container, .hide-ww-cookiewall .ww-cookiewall {
  display: none;
}

.ww-cookiewall {
  position: relative;
  z-index: 20000;
  display: none;
  background: var(--ww-cookiewall-background-color);
}
.ww-cookiewall--acceptdecline {
  position: fixed;
}
.ww-cookiewall.hide {
  display: none;
}
.ww-cookiewall.show {
  display: block;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__container {
  padding: 32px 16px;
}
.ww-cookiewall__text > *:first-child {
  margin-top: 0;
}
.ww-cookiewall__text > *:last-child {
  margin-bottom: 0;
}
.ww-cookiewall__text,
.ww-cookiewall__text > * {
  color: var(--ww-cookiewall-text-color);
}
.ww-cookiewall__text a {
  color: var(--ww-cookiewall-link-text-color);
}

.ww-cookiewall--specific {
  background: var(--ww-cookiewall-backdrop-color);
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: none;
}

.ww-cookiewall--specific .ww-cookiewall__container {
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  position: relative;
  max-width: 95vw;
  min-width: 325px;
  width: max-content;
  background-color: var(--ww-cookiewall-background-color);
  color: var(--ww-cookiewall-text-color);
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  padding: 1.5em;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall--specific .ww-cookiewall__container {
    max-width: 600px;
    padding: 3em 3.5em;
  }
}

.ww-cookiewall__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall__buttons {
    flex-direction: row;
    gap: 0;
  }
}

.ww-cookiewall .btn {
  width: 100%;
  padding: 0.5em 1rem;
  font-size: inherit;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall .btn {
    width: 60%;
  }
}

.ww-cookiewall__link--check {
  color: var(--ww-cookiewall-link-text-color);
  cursor: pointer;
}

.ww-cookiewall--specific #gdpr-cookie-message {
  transition: all 3.5s ease;
  -webkit-transition: all 3.3s ease; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 3.3s ease;
}

.ww-cookiewall--specific #gdpr-cookie-types {
  display: none;
  transition: all 3.5s ease;
  -webkit-transition: all 3.3s ease; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 3.3s ease;
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall--specific #gdpr-cookie-types {
    margin: 0;
  }
}

.ww-cookiewall__message {
  max-width: 450px;
  margin: 0 auto;
}
.ww-cookiewall__message > * {
  font-size: 14px;
  margin-bottom: 1.5rem;
}
.ww-cookiewall__message > p:first-of-type {
  color: var(--ww-cookiewall-heading-color);
  font-size: 18px;
}
.ww-cookiewall__message > *:last-child {
  margin-bottom: 0;
}

.ww-cookiewall--specific .wh-form__fieldline {
  padding: 0.5em;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall--specific .wh-form__fieldline {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.ww-cookiewall--specific .wh-form__fieldline .switch {
  margin-right: 0.75em;
}

#gdpr-cookie-check, #gdpr-cookie-check-back, #gdpr-cookie-save {
  cursor: pointer;
}

#gdpr-cookie-check, #gdpr-cookie-save {
  text-decoration: underline;
}

#gdpr-cookie-check-back {
  font-size: 1.25rem;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 768px) {
  #gdpr-cookie-check-back {
    margin-bottom: 0.7em;
  }
}

.ww-cookiewall__cookie-items {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding-left: 0;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall__cookie-items {
    width: 50%;
  }
}

.ww-cookiewall__cookie-item {
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.ww-cookiewall__cookie-item-all {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .ww-cookiewall__cols {
    display: flex;
  }
  .ww-cookiewall__col--buttons {
    padding-left: 50px;
  }
  .ww-cookiewall--acceptdecline .ww-cookiewall__cols {
    align-items: center;
  }
  .ww-cookiewall--acceptdecline .ww-cookiewall__col--text {
    margin-bottom: 0;
  }
}
.ww-cookiewall--acceptdecline {
  bottom: 0;
  left: 0;
  right: 0;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__container {
  padding: 2rem 0;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__col--text {
  display: flex;
  align-items: center;
  flex: 1;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__col--buttons {
  display: flex;
  align-items: center;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__button {
  margin-right: 15px;
}
.ww-cookiewall--acceptdecline .ww-cookiewall__button:last-child {
  margin-right: 0;
}

.wh-requireconsent {
  position: relative;
  z-index: 0;
}
.wh-requireconsent__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.wh-requireconsent__overlay a {
  color: inherit;
}
.wh-requireconsent__overlay[hidden] {
  display: none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Styling for disabled item */
.switch input[disabled] + .slider {
  opacity: 0.5;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--ww-cookiewall-switch-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Imports */
.wh-form__imgedit {
  width: 200px;
  height: 200px;
}
.wh-form__imgeditdelete {
  cursor: pointer;
}
.wh-form__imgeditholder::after {
  z-index: 1;
  position: absolute;
  display: none;
  width: 60px;
  top: 50%;
  left: 50%;
  height: 60px;
  font-size: 60px;
  line-height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  opacity: 0.8;
}
.wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form--uploading .wh-form__imgedit::after,
.wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

.wh-form__textinput, .wh-form__textinput[type=password], .wh-form__uploadfieldfilename, .wh-form__textarea {
  width: 100%;
  font-size: var(--form-textinput-font-size);
  line-height: 100%;
  color: #000;
  font-weight: normal;
  background: #fff;
  border-radius: 4px;
  box-shadow: none;
  padding: 10px;
  margin: 0;
  border: 1px solid #000;
  font-family: inherit;
}
.wh-form__textinput[disabled], .wh-form__textinput[type=password][disabled], .wh-form__uploadfieldfilename[disabled], .wh-form__textarea[disabled] {
  background: var(--ww-form-textinput-disabled-bg-color);
}
.wh-form__textarea {
  min-height: 120px;
}
.wh-form__dateinputgroup {
  max-width: 200px;
}
.wh-form__timeinputgroup {
  max-width: 140px;
}
.wh-form__textinput[type=number] {
  max-width: 200px;
}

.wh-form__uploadfield {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.wh-form__uploadfieldselect, .wh-form__uploadfieldfilename {
  cursor: pointer;
}
.wh-form__uploadfieldselect {
  white-space: nowrap;
  padding: 8px 24px;
  min-height: initial;
}
.wh-form__uploadfieldinputholder {
  flex-grow: 1;
  min-width: 300px;
  max-width: 100%;
}
.wh-form__uploadfieldfilename {
  width: 100%;
}
.wh-form__uploadfielddelete {
  top: 50%;
  transform: translate(-2px, -50%);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  width: 20px;
  line-height: 100%;
  position: absolute;
  right: 0;
}

/* Styling */
.wh-form__fieldgroup--required label::after {
  margin-left: 5px;
  content: "*";
  color: var(--form-color-error-required-indicator);
}
.wh-form__fields {
  width: 100%;
}
.wh-form__navbuttons {
  display: flex;
  justify-content: space-between;
}
.wh-form__button--next {
  margin-left: auto;
}
.wh-form__label {
  cursor: pointer;
}
.wh-form__fieldgroup {
  margin: 0 0 16px;
}
.wh-form__checkbox, .wh-form__radio {
  transform: scale(1.3);
  margin-right: 11px;
}
.wh-form__fieldgroup--checkbox.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after, .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--checkboxgroup .wh-form__optionlabel::after, .wh-form__fieldgroup--radiogroup.wh-form__fieldgroup--required .wh-form__fieldline > label,
.wh-form__fieldgroup--radiogroup .wh-form__optionlabel::after {
  display: none;
}
.wh-form__fieldgroup--checkbox .wh-form__textinput, .wh-form__fieldgroup--checkboxgroup .wh-form__textinput, .wh-form__fieldgroup--radiogroup .wh-form__textinput {
  margin-left: 15px;
}
.wh-form__optionlabel {
  cursor: pointer;
}
.wh-form__fieldgroup--error .wh-form__textinput,
.wh-form__fieldgroup--error .wh-form__uploadfieldfilename,
.wh-form__fieldgroup--error .wh-form__textarea,
.wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: var(--ww-form-color-error-border);
}
.wh-form__error {
  color: var(--ww-form-color-error-message);
}

a {
  transition: color 0.3s;
}

html.ww-fixedheader body {
  padding-top: var(--ww-header-height);
}
html.ww-fixedheader body.mm-wrapper_opened #my-page {
  top: calc(-1 * var(--ww-header-height));
}
html.ww-fixedheader body.mm-wrapper_opened main {
  padding-top: var(--ww-header-height);
}

.ww-chevron--down {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.ww-chevron--down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

body,
#my-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
body > main,
#my-page > main {
  flex: 1;
}

.wh-rtd__table {
  width: 100%;
}

.wh-rtd__tablewrap.table {
  overflow-x: auto;
}

.ww-grid, main > h1,
main > h2,
main > h3,
main > h4,
main > p.normal,
main > ul,
main > ol,
main > .widget--expandabletabs,
main > .widget--button,
main > .widget--ctas > .w-ctas,
main > .widget--formwidget,
main > .widget--textvideo,
main > .widget--overview,
main > .widget--slideshow-in-grid,
main > .widget--customtext,
main > .widget--textimage > .w-textimage,
main > .widget--twocols,
main > .widget--photoalbum,
main > .wh-video[data-video], html.page-form main form.wh-form,
main > .wh-rtd__tablewrap {
  max-width: calc(var(--ww-grid-width) + var(--ww-grid-margin-outer));
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--ww-grid-padding-x);
  padding-right: var(--ww-grid-padding-x);
}

main > h1.full-width,
main > h2.full-width,
main > h3.full-width,
main > h4.full-width,
main > p.normal.full-width,
main > ul.full-width,
main > ol.full-width,
main > .widget--expandabletabs.full-width,
main > .widget--button.full-width,
main > .widget--ctas > .w-ctas.full-width,
main > .widget--formwidget.full-width,
main > .widget--textvideo.full-width,
main > .widget--overview.full-width,
main > .widget--slideshow-in-grid.full-width,
main > .widget--customtext.full-width,
main > .widget--textimage > .w-textimage.full-width,
main > .widget--twocols.full-width,
main > .widget--photoalbum.full-width,
main > .wh-video[data-video].full-width, html.page-form main form.wh-form.full-width,
main > .wh-rtd__tablewrap.full-width {
  max-width: unset;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
main > h1.full-width > div,
main > h2.full-width > div,
main > h3.full-width > div,
main > h4.full-width > div,
main > p.normal.full-width > div,
main > ul.full-width > div,
main > ol.full-width > div,
main > .widget--expandabletabs.full-width > div,
main > .widget--button.full-width > div,
main > .widget--ctas > .w-ctas.full-width > div,
main > .widget--formwidget.full-width > div,
main > .widget--textvideo.full-width > div,
main > .widget--overview.full-width > div,
main > .widget--slideshow-in-grid.full-width > div,
main > .widget--customtext.full-width > div,
main > .widget--textimage > .w-textimage.full-width > div,
main > .widget--twocols.full-width > div,
main > .widget--photoalbum.full-width > div,
main > .wh-video[data-video].full-width > div, html.page-form main form.wh-form.full-width > div,
main > .wh-rtd__tablewrap.full-width > div {
  max-width: var(--ww-grid-width);
  margin: 0 auto;
}

main > ul li,
main > ol li {
  margin-left: 1em;
}

.wh-video {
  display: flex;
}

.wh-video iframe {
  position: static;
  height: auto !important;
}

.wh-video.wh-video--aspect_16_9 .wh-video__innerframe:after {
  padding-top: initial;
}

.wh-video--aspect_16_9 iframe {
  aspect-ratio: 16/9;
}

.wh-video--aspect_4_3 iframe {
  aspect-ratio: 4/3;
}

img {
  max-width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--ww-widget-slideshow-navigation-color);
}

.swiper-pagination-bullet-active {
  background: var(--ww-widget-slideshow-pagination-color);
}

.ww-copyrightfooter {
  background: var(--ww-copyrightbar-bg-color);
  color: var(--ww-copyrightbar-text-color);
  padding: var(--ww-copyrightbar-padding);
}
.ww-copyrightfooter a {
  color: var(--ww-copyrightbar-link-color);
  text-decoration: underline;
}
@media (hover: hover) {
  .ww-copyrightfooter a:hover {
    text-decoration: none;
  }
}
.ww-copyrightfooter__grid > *:first-child {
  margin-top: 0;
}
.ww-copyrightfooter__grid > *:last-child {
  margin-bottom: 0;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before,
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
  background-color: var(--ww-hamburger-color);
}

.mm-menu .mm-ctabutton {
  font-weight: 800;
}

a:not(.btn) {
  color: var(--ww-link-color);
}

/* Buttons */
.btn,
.btn-primary,
.wh-form__button {
  cursor: pointer;
  height: auto;
  transition: color 0.3s, background 0.3s, box-shadow 0.3s, border 0.3s;
  padding: var(--ww-button-padding);
  border-color: var(--ww-button-border-color);
  border: var(--ww-button-border);
  border-radius: var(--ww-button-border-radius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ww-button-bg-color);
  color: var(--ww-button-text-color);
  text-decoration: none;
  text-transform: var(--ww-button-text-transform);
  font-size: var(--ww-button-font-size);
  line-height: var(--ww-button-line-height);
}
.btn:focus,
.btn-primary:focus,
.wh-form__button:focus {
  background-color: var(--ww-button-bg-color);
}
@media (hover: hover) {
  .btn:hover,
  .btn-primary:hover,
  .wh-form__button:hover {
    background-color: var(--ww-button-bg-color-hover);
    color: var(--ww-button-text-color-hover);
    border-color: var(--ww-button-border-color-hover);
  }
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

.form-select {
  width: auto;
}

.ww-grid > .container-lg, main > h1 > .container-lg,
main > h2 > .container-lg,
main > h3 > .container-lg,
main > h4 > .container-lg,
main > p.normal > .container-lg,
main > ul > .container-lg,
main > ol > .container-lg,
main > .widget--expandabletabs > .container-lg,
main > .widget--button > .container-lg,
main > .widget--ctas > .w-ctas > .container-lg,
main > .widget--formwidget > .container-lg,
main > .widget--textvideo > .container-lg,
main > .widget--overview > .container-lg,
main > .widget--slideshow-in-grid > .container-lg,
main > .widget--customtext > .container-lg,
main > .widget--textimage > .w-textimage > .container-lg,
main > .widget--twocols > .container-lg,
main > .widget--photoalbum > .container-lg,
main > .wh-video[data-video] > .container-lg, html.page-form main form.wh-form > .container-lg,
main > .wh-rtd__tablewrap > .container-lg {
  padding-left: 0;
  padding-right: 0;
}

.w-logos .w-logos__logoscontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
@media screen and (min-width: 450px) {
  .w-logos .w-logos__logoscontainer {
    justify-content: flex-start;
    flex-flow: row wrap;
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .w-logos .w-logos__logoscontainer {
    gap: 0;
    justify-content: space-between;
  }
}
.w-logos .w-logos__logo {
  display: block;
  width: auto;
  max-height: 45px;
}

main > .addpagecontent {
  height: 100px;
}

main {
  margin-top: 3rem;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
}

:root {
  --color-orange: #d7b740;
  --color-blue: #6A8EC5;
  --ww-grid-width: 940px;
  --ww-primary-color: var(--color-blue);
  --ww-base-font-family: "Open Sans", sans-serif;
  --ww-base-font-size: 1rem;
  --ww-base-font-weight: 300;
  --ww-base-line-height: 1.875em;
  --ww-button-bg-color-hover: #fff;
  --ww-button-border-radius: 0;
  --ww-button-border:1px solid var(--ww-primary-color);
  --ww-button-font-size: 14px;
  --ww-button-padding: 0.6rem 1.5rem;
  --ww-button-text-color-hover: var(--ww-primary-color);
  --ww-button-text-transform: uppercase;
  --ww-copyrightbar-bg-color: #000;
  --ww-copyrightbar-padding: 1.5rem 0;
  --ww-copyrightbar-text-color: #fff;
  --ww-font-h1-line-height: 1.25em;
  --ww-font-h1-size: 2.5rem;
  --ww-font-h1-weight: 300;
  --ww-font-h2-size: 2rem;
  --ww-font-h3-size: 1.75rem;
  --ww-font-h4-size: 1.5rem;
  --ww-font-h2-line-height: 1.5em;
  --ww-font-h3-line-height: 1.5em;
  --ww-font-h4-line-height: 1.5em;
  --ww-footer-margin-top: 6rem;
  --ww-header-bg-color: #fff;
  --ww-header-height: 100px;
  --ww-header-link-selected-color: var(--color-blue);
  --ww-header-logo-height: 70px;
  --ww-header-text-color: #000;
  --ww-header-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  --ww-link-color: var(--color-blue);
  --ww-widget-ctas-image-border-radius: 0;
  --ww-widget-ctas-text-background-color: #fff;
  --ww-widget-ctas-text-padding: 2rem;
  --ww-widget-textimage-border-radius: 0;
  --ww-mobilemenu-link-color: var(--color-blue);
  --ww-mobilemenu-link-selected-color: #fff;
}

.ww-cookiewall {
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.4);
}

.ww-cookiewall__text {
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .ww-cookiewall__text {
    margin-bottom: 0;
  }
}

/* Fix for firefox */
strong, b {
  font-weight: bold;
}

html.init body {
  opacity: 1;
}

html.is-scrolling {
  --ww-header-height: 100px;
  --ww-header-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  --ww-header-logo-height: 70px;
}

.w-ctas__text {
  text-align: center;
}

.w-ctas--blockstype-textbelow .w-ctas__text {
  align-items: center;
}

.w-ctas__cta-subtitle {
  font-weight: bold;
}

@media (min-width: 768px) {
  .w-ctas__container--3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 900px) {
  .w-ctas__container--3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
.ww-copyrightfooter {
  margin-top: var(--ww-footer-margin-top);
}

.ww-copyrightfooter__grid {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 140%;
}

.crumbpath__outergrid {
  display: none;
}

.ww-header__nav {
  justify-content: space-between;
}

.ww-header__logolinkcontainer {
  order: 2;
}

.ww-menu-link {
  font-weight: 600;
  letter-spacing: 0.05em;
}

.ww-header__nav .ww-header__desktopmenu > ul.ww-menu > li.ww-menu-item .ww-menu-link {
  padding-top: 0;
  padding-bottom: 0;
}

.ww-menu-item:first-child {
  margin-left: 0 !important;
}

.widget--textimage-left:not(.w-textimage--variant-bgcolor),
.widget--textimage-right:not(.w-textimage--variant-bgcolor),
.widget--twocols {
  position: relative;
}
.widget--textimage-left:not(.w-textimage--variant-bgcolor)::before, .widget--textimage-left:not(.w-textimage--variant-bgcolor)::after,
.widget--textimage-right:not(.w-textimage--variant-bgcolor)::before,
.widget--textimage-right:not(.w-textimage--variant-bgcolor)::after,
.widget--twocols::before,
.widget--twocols::after {
  content: "";
  width: 72px;
  height: 11px;
  background: #B0C936;
  position: absolute;
  pointer-events: none;
}
@media (max-width: 1111px) {
  .widget--textimage-left:not(.w-textimage--variant-bgcolor)::before, .widget--textimage-left:not(.w-textimage--variant-bgcolor)::after,
  .widget--textimage-right:not(.w-textimage--variant-bgcolor)::before,
  .widget--textimage-right:not(.w-textimage--variant-bgcolor)::after,
  .widget--twocols::before,
  .widget--twocols::after {
    display: none;
  }
}
.widget--textimage-left:not(.w-textimage--variant-bgcolor)::before,
.widget--textimage-right:not(.w-textimage--variant-bgcolor)::before,
.widget--twocols::before {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 0;
  left: 0;
}
.widget--textimage-left:not(.w-textimage--variant-bgcolor)::after,
.widget--textimage-right:not(.w-textimage--variant-bgcolor)::after,
.widget--twocols::after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  top: calc(100% + 25px);
  right: 0;
}

.widget--twocols {
  width: 100%;
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.widget--twocols .w-twocols {
  max-width: calc(var(--ww-grid-width) + var(--ww-grid-margin-outer));
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--ww-grid-padding-x);
  padding-right: var(--ww-grid-padding-x);
}

.w-textimage--variant-bgcolor {
  background-color: rgba(176, 201, 54, 0.25);
  padding-top: 32px;
  padding-bottom: 32px;
  --ww-widget-textimage-column-text-width: 44%;
  --ww-widget-textimage-column-image-width: 49%;
  --ww-widget-textimage-text-align-items: flex-start;
}
@media (min-width: 768px) {
  .w-textimage--variant-bgcolor {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 900px) {
  .w-textimage--variant-bgcolor {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 768px) {
  .widget.widget--textimage.widget--textimage-right:not(.w-textimage--variant-bgcolor) {
    display: flex;
  }
  .widget.widget--textimage.widget--textimage-right:not(.w-textimage--variant-bgcolor) .w-textimage {
    display: flex;
  }
  .widget.widget--textimage.widget--textimage-right:not(.w-textimage--variant-bgcolor) .w-textimage__imagecontainer {
    position: relative;
  }
  .widget.widget--textimage.widget--textimage-right:not(.w-textimage--variant-bgcolor) .w-textimage__image {
    position: absolute;
    top: 0;
    left: 0;
    --grid-half: calc(var(--ww-grid-width)/2);
    --margin-outside: calc((100vw - var(--ww-grid-width))/2);
    width: calc(var(--grid-half) + var(--margin-outside) - 32px);
    max-width: initial;
  }
  .widget.widget--textimage.widget--textimage-right:not(.w-textimage--variant-bgcolor) .w-textimage__container {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
  .widget.widget--textimage.widget--textimage-right:not(.w-textimage--variant-bgcolor) .w-textimage__divider {
    display: none;
  }
  .widget--twocols.twocolcontactform .w-twocols__col--right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 70px;
  }
  .widget--twocols.twocolcontactform .w-twocols__cols {
    column-gap: 5rem;
  }
}