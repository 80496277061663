.w-textimage__textcontainer, .w-textimage__imagecontainer {
  display: flex;
}
.w-textimage__textcontainer {
  order: 1;
  align-items: var(--ww-widget-textimage-text-align-items);
}
.w-textimage__divider {
  min-height: 2rem;
}
.w-textimage__text > *:first-child {
  margin-top: 0;
}
.w-textimage__text > *:last-child {
  margin-bottom: 0;
}
.w-textimage__imagecontainer {
  order: 3;
  align-items: var(--ww-widget-textimage-image-align-items);
}
.w-textimage__divider {
  order: 2;
}
.w-textimage--imageleft .w-textimage__container {
  grid-template-columns: var(--ww-widget-textimage-column-image-width) auto var(--ww-widget-textimage-column-text-width);
}
.w-textimage--imageleft .w-textimage__textcontainer {
  order: 3;
}
.w-textimage--imageleft .w-textimage__imagecontainer {
  order: 1;
}
.w-textimage__image {
  max-width: 100%;
  border-radius: var(--ww-widget-textimage-border-radius);
  display: block;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .w-textimage__container {
    display: grid;
    grid-template-columns: var(--ww-widget-textimage-column-text-width) auto var(--ww-widget-textimage-column-image-width);
  }
}