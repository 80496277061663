.w-twocols {
  --ww-w-twocols-width-left: 1fr;
  --ww-w-twocols-width-right: 1fr;
  --ww-w-twocols-gap: 2rem;
}
.w-twocols__col {
  margin-bottom: 2rem;
}
.w-twocols__col:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .w-twocols__cols {
    display: grid;
    grid-template-columns: var(--ww-w-twocols-width-left) var(--ww-w-twocols-width-right);
    gap: var(--ww-w-twocols-gap);
  }
  .w-twocols__col {
    margin-bottom: 0;
  }
}