.w-button {
  display: flex;
}
.w-button--left {
  justify-content: flex-start;
}
.w-button--center {
  justify-content: center;
}
.w-button--right {
  justify-content: flex-end;
}
.w-button .btn {
  min-width: var(--ww-button-min-width);
}