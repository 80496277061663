.ww-arrow {
  display: inline-block;
  height: 12px;
  position: relative;
  width: 12px;
}
.ww-arrow::after {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: "";
  display: inline-block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}
.ww-arrow.ww-arrow--triangle::after {
  border-style: solid;
  border-width: 0 0 12px 12px;
  height: 0;
  width: 0;
}
.ww-arrow.ww-arrow--check::after {
  width: calc(12px / 2);
}
.ww-arrow.ww-arrow--bar::before {
  bottom: 1px;
  content: "";
  height: 20px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 2px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ww-arrow.ww-arrow--top {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.ww-arrow.ww-arrow--right {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.ww-arrow.ww-arrow--bottom {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ww-arrow.ww-arrow--left {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.ww-arrow::after,
.ww-arrow .ww-arrow::after {
  border-color: var(--ww-primary-color);
}
.ww-arrow.ww-arrow--triangle::after,
.ww-arrow .ww-arrow.ww-arrow--triangle::after {
  border-color: transparent;
  border-bottom-color: var(--ww-primary-color);
}
.ww-arrow::before,
.ww-arrow .ww-arrow::before {
  background-color: var(--ww-primary-color);
}